import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { RequestFormModel } from 'src/app/requests/request-form.model';
import { RequestMasterModel } from 'src/app/requests/request-master.model';
import { TruckLoadRequestFormModel } from 'src/app/requests/truck-load-request-form.model';
import { TruckLoadRequestMasterModel } from 'src/app/requests/truck-load-request-master.model';
import { ProductModel } from 'src/app/shared/product/products.model';
import { LoggedInUserInfoModel } from 'src/app/site-permissions/loggedin-user-access.model';
import { CommonRequestComponent } from '../../request-form-common.component';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { AppServiceService } from 'src/app/services/app-service.service';
import { TruckLoadRequestMasterData } from 'src/app/requests/data/masterdata/truck-load-request-master.data';
import { TruckLoadRequestFormData } from 'src/app/requests/data/formdata/truck-load-request-form.data';

@Component({
  selector: 'app-truck-load-request-form-ap',
  templateUrl: '..\\..\\..\\shared-templates\\request-form-template.html',
  styleUrls: ['..\\..\\..\\shared-templates\\request-form-template.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [LoggedInUserInfoModel, ProductModel,
    { provide: RequestFormModel, useClass: TruckLoadRequestFormModel },
    { provide: RequestMasterModel, useClass: TruckLoadRequestMasterModel },
   
  ]
})
export class TruckLoadRequestFormAPComponent extends CommonRequestComponent implements OnInit, OnDestroy {

     override requestData:any
     override requestMaster:any
  pageLanguage:string="";
  constructor(userAccessModel: LoggedInUserInfoModel, masterModel: RequestMasterModel,
      private productModel: ProductModel, requestModel: RequestFormModel,
      toast: ToastrService, router: Router, spinner: NgxSpinnerService,
       private spinners: NgxSpinnerService, route: ActivatedRoute,translate:TranslateService,private _appServ: AppServiceService) {          
      super(userAccessModel, masterModel, requestModel, router, toast, spinner,route,translate);
      translate.stream('Truckload').subscribe((res: string) => {
        this.ngOnInit();
      });
    }
    ngOnDestroy() {
      console.log(''); 
  }
  ngOnInit(){
    this.pageLanguage=sessionStorage['selectedLanguage'];
    this.transportType = 3;
      this.startDateLable="START_DATE";
      this.pageLanguage=sessionStorage['selectedLanguage'];
      this.requestMaster = new TruckLoadRequestMasterData();
       this.requestData = new TruckLoadRequestFormData();
       this.requestData.equipmentTypeSelectedIds=[];
       this.requestData.ltlRangeSelectedIds=[];
      this.requestData.transportType = 3;
      this.bindfiledVisiblity();
      this.retrieveHeaderValues();

      this.validateloggedInUser();
      this.retrieveMasterData(this.transportType);
      this.retrieveQueryPramaters();
      this.retriveLang(this.pageLanguage);

  }
  retrieveHeaderValues() {
    this.title = "PACKED_TRANSAPORTATION";
    this.description = "A \"Rush Request\" defined as Procurement providing rates to BU after 2 business days otherwise Procurement will provide rates within 7 business days for standard requests.";
  }
  bindfiledVisiblity()
  {
    this.fieldVisiblity.requestType=true;
    this.fieldVisiblity.naCountry=true;
    this.fieldVisiblity.startDate=true;
    this.fieldVisiblity.requestor=true;
    this.fieldVisiblity.bussinessunit=true;
    this.fieldVisiblity.articleNumber=true;    
    this.fieldVisiblity.product=true;
    this.fieldVisiblity.IsProductReadOnly=false;
    this.fieldVisiblity.equipmentType=true;

    this.fieldVisiblity.equipmentTypeMultiSelect=true;
    this.fieldVisiblity.packageType=true;
    this.fieldVisiblity.remarkPackageType=true;
    this.fieldVisiblity.shippingWithPallet=true;
    this.fieldVisiblity.palletSize=true;
    this.fieldVisiblity.ftlOrLtl=true;
    this.fieldVisiblity.ftlTruckType=true;
    this.fieldVisiblity.ltlRange=true;
    this.fieldVisiblity.allowTwoLayerStack=true;
    this.fieldVisiblity.interModel=true;

      // Service Provider
      this.fieldVisiblity.volumeType=true;
      this.fieldVisiblity.annualVolume=true;
      this.fieldVisiblity.tempControl=true;
      this.fieldVisiblity.dropTrail=true;
      this.fieldVisiblity.hazardousMandatory=true;
      this.fieldVisiblity.unNumber=true;

      this.fieldVisiblity.shipmentFrequency=true;
      this.fieldVisiblity.tempControl=true;
      this.fieldVisiblity.driverSupportRequired=true;
      this.fieldVisiblity.dangerousGoodsClass=true;
      this.fieldVisiblity.tariffZone=true;
  
      //Shipping->Orgin
      this.fieldVisiblity.originPlant = true;
      this.fieldVisiblity.shippingName=true;
      this.fieldVisiblity.orginCity=true;
      this.fieldVisiblity.orginState=true;
      this.fieldVisiblity.orginCountry=true;
      this.fieldVisiblity.orginZipCode=true;
      //shipping-> destination
      this.fieldVisiblity.destinationPlant = true;
      this.fieldVisiblity.consigneeName=true;
      this.fieldVisiblity.destinationCity=true;
      this.fieldVisiblity.destinationState=true;
      this.fieldVisiblity.destinationCountry=true;
      this.fieldVisiblity.destinationZipCode=true;
  
      //Additional Info
      this.fieldVisiblity.customerPreferedCarrier=true;
      this.fieldVisiblity.costVsService=true;
      this.fieldVisiblity.comments=true;
  
      this.fieldVisiblity.materialDoc=true;
      this.fieldVisiblity.materialLink=true;
      this.fieldVisiblity.sdsLink=true;
      this.fieldVisiblity.sdsAttachment=true;
      this.fieldVisiblity.additionalAttachment=true;
      this.fieldVisiblity.additionalAttachment2=true;
      this.fieldVisiblity.hazardousSDSMandatory=true;

      this.fieldVisiblity.sdsNote=true;
      this.fieldVisiblity.additionalremarkNote=true;
   
  }
  handlehazardousChange(event:any)
  {
    if(this.requestData.hazardous=="No")
    {
    
      this.requestData.unNumber="";
    }
  }
  override handleSeasonalChange(event:any)
  {
    if(this.requestData.seasonal=="No")
    {
      this.requestData.seasonalComments="";
     
    }
  }
  
  onAnnualVolumeChange(event: any){
    console.log('');
  }
  onArticleSelected(event:any){
  
    this.processArticleNumber(event);

  }
  onArticleComplete(event:any){
    super.retrieveProductDetails(event);
    
   }


  GenerarateArticleNumberEvent() {
    this.selectedArticle.label = this.requestData.articleNumber;
    return {
      label: this.requestData.articleNumber,
      value:this.requestData.product
    };
    
  }
  resetArticleData() {
    this.selectedArticle=[]; 
    this.requestData.articleNumber="";
    
  }
  processArticleNumber(event:any){
    this.requestMaster.mfgOpts=[];
    this.selectedMFGCode="";
    this.requestData.articleNumber = event.label;
    this.requestData.product=event.value;   
    
  }
  public clearForm() {
    this.OnCancel();
    }
    
  override OnCancel() {
    this.clearData(3);
    this.requestData.packageTypeId=0;
    this.requestData.remarkPackageType="";
    this.requestData.shippingWithPallet="";
    this.requestData.palletSize="";
    this.requestData.ftlOrLtlId=0;
    this.requestData.equipmentTypeSelectedList=[];
    this.requestData.equipmentTypeSelectedIds=[];
    this.requestData.ftlTruckType="";
    this.requestData.ltlRangeSelectedList=[];
    this.requestData.ltlRangeSelectedIds=[];
    this.requestData.allowTwoLayerStack="";
    this.requestData.internModel="";
    this.requestData.intermodalTypeId=0;
    this.requestData.containerTypeId=0;
    this.requestData.equipmentTypeId=0;
    this.requestData.naCountry="";

    //Service provider
    this.requestData.days=0;
    this.requestData.dropTrail="";
    this.requestData.shipmentFrequency=0;
    this.requestData.driverSupportRequired="";
    this.requestData.tempRange="";
    this.requestData.hazardous="";
    this.requestData.tempControl="";
    this.requestData.unNumber="";
    this.requestData.tariffZone="";
    this.requestData.dangerousGoodsClassId=0;
    this.requestData.naCountry=0;
    this.clearData(3)
    this.resetArticleData();
  }

  onSubmitRequest() {
    this.handlingDefaultDropdownValues();
    this.requestData.packageTypeId=this.requestData.packageTypeId??0;
    this.requestData.ftlOrLtlId=this.requestData.ftlOrLtlId??0;
    this.requestData.equipmentTypeId=this.requestData.equipmentTypeId??0;
    this.requestData.annualVolumeUnitId=this.requestData.annualVolumeUnitId??0;
    this.requestData.equipmentTypeSelectedIds=this.requestData.equipmentTypeSelectedList.map((element:any)=>element.value).join(',');
    this.requestData.ltlRangeSelectedIds=this.requestData.ltlRangeSelectedList.map((element:any)=>element.value).join(',');
    this.requestData.dangerousGoodsClassId=this.requestData.dangerousGoodsClassId??0;
    this.createRequest();
  }


  requestTypeChangeEvent(event:any){
    console.log('');
}
bindConditionalFields(){
  
  if(this.requestData.internModel=="Yes")
  {
    this.fieldVisiblity.intermodalType=true;
    this.fieldVisiblity.containerType=true;
  }
  this.fieldVisiblity.tempRange=this.requestData.tempControl=="Yes";
  this.fieldVisiblity.isTempRangeMandatory=this.requestData.tempControl=="Yes";
  if(this.requestData.requestId){
    let equipmentTypeMultipleValues=this.requestData.equipmentTypeSelectedIds?.split(',');
    this.requestData.equipmentTypeSelectedList=[];  
    equipmentTypeMultipleValues?.forEach((element:any) => {    
      this.requestData.equipmentTypeSelectedList.push(this.requestMaster.equipementTypeMaster.find((dd:any)=>dd.value==parseInt(element)));
    });
    let ltlRangeMultipleValues=this.requestData.ltlRangeSelectedIds.split(',');
    this.requestData.ltlRangeSelectedList=[];
    ltlRangeMultipleValues.forEach((element:any) => {    
      this.requestData.ltlRangeSelectedList.push(this.requestMaster.ltlrangeMaster.find((dd:any)=>dd.value==parseInt(element)));
    });
  }
}
onPlantSelectPopulate(event:any,index:number){
  console.log('');

}


onIntermodalAcceptChange(event:any){
  if(event.target.value=="Yes")
  {
    this.fieldVisiblity.intermodalType=true;
    this.fieldVisiblity.containerType=true;
    
  }
  else{
    this.fieldVisiblity.intermodalType=false;
    this.fieldVisiblity.containerType=false;
    this.requestData.containerTypeId=0;
    this.requestData.intermodalTypeId=0;
  }
}

onTempControlChange(event:any){
  if(event.target.value=="Yes")
  {
    this.fieldVisiblity.tempRange=true;
    this.fieldVisiblity.isTempRangeMandatory=true;
  }
  else{
    this.fieldVisiblity.tempRange=false;
    this.fieldVisiblity.isTempRangeMandatory=false;
    this.requestData.tempRange="";
  }
}

}
