import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ProductModel } from "src/app/shared/product/products.model";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { RailRateRequestFormData } from "../data/formdata/rail-rate-form.data";
import { RailRateRequestMasterData } from "../data/masterdata/rail-rate-request-master.data";
import { RailRequestFormModel } from "../rail-rate-request-form.model";
import { RailRateRequestMasterModel } from "../rail-rate-request-master.model";
import { RequestFormModel } from "../request-form.model";
import { RequestMasterModel } from "../request-master.model";
import { CommonRequestComponent } from "./request-form-common.component";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { AppServiceService } from "src/app/services/app-service.service";



@Component(
    {
      selector: 'app-rail-rate-request-form',
      templateUrl: '..\\shared-templates\\request-form-template.html',
      styleUrls: ['..\\shared-templates\\request-form-template.css'],
      encapsulation: ViewEncapsulation.None,
      providers: [LoggedInUserInfoModel, ProductModel,
        { provide: RequestFormModel, useClass: RailRequestFormModel },
        { provide: RequestMasterModel, useClass: RailRateRequestMasterModel }
  
      ]
    }
  )
  
export class RailRateRequestFormComponent extends CommonRequestComponent implements OnInit,OnDestroy
{
  private subscriptionName: Subscription; //important to create a subscription
  messageReceived: any;
  pageLanguage:string="";
    

//  override requestMaster: RailRateRequestMasterData = new RailRateRequestMasterData;
//  override requestData: RailRateRequestFormData = new RailRateRequestFormData;
 override requestMaster:any
 override requestData:any
  _toast: ToastrService;
  _translate:TranslateService
_requestModel: RequestFormModel;
  constructor(userAccessModel: LoggedInUserInfoModel, masterModel: RequestMasterModel,
    private productModel: ProductModel, requestModel: RequestFormModel,
    toast: ToastrService, router: Router, spinner: NgxSpinnerService,
     private spinners: NgxSpinnerService, route: ActivatedRoute,translate:TranslateService,private _appServ: AppServiceService) {
    super(userAccessModel, masterModel, requestModel, router, toast, spinner,route,translate);
   this. _requestModel=requestModel;
   this._toast=toast;
   this._translate=translate;
   this.subscriptionName= this._appServ.getUpdate().subscribe(message => { //message contains the data sent from service
          this.messageReceived = message;    
          this.ngOnInit();
        });
  }
  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.subscriptionName.unsubscribe();
  }



  ngOnInit(): void {
    this.startDateLable="EXPECTED_SHIPMENT_DATE";
    this.prefferedCarriear="Preferred Routing"; 
    this.pageLanguage=sessionStorage['selectedLanguage'];
    this.transportType = 4;
    this.requestMaster = new RailRateRequestMasterData();
    this.requestData = new RailRateRequestFormData();
    this.requestData.transportType = 4;
    this.bindfiledVisiblity();
    this.retrieveHeaderValues();
    //login
    this.validateloggedInUser();
    //Request Master
    this.retrieveMasterData(this.transportType);
    //Article
    // this.retrieveArticleList(this.spinners)
    //retrieve Query Parameters
    this.retrieveQueryPramaters();
    this.retriveLang(this.pageLanguage);

  }
  bindfiledVisiblity()
  {

    this.fieldVisiblity.requestType=true;
    this.fieldVisiblity.startDate=true;
    this.fieldVisiblity.requestor=true;
    this.fieldVisiblity.bussinessunit=true;
    this.fieldVisiblity.articleNumber=true;
    this.fieldVisiblity.product=true;
    this.fieldVisiblity.mfgDescription=true;
    this.fieldVisiblity.mfgDropdown=true;
    this.fieldVisiblity.railCarType=true;
    this.fieldVisiblity.payerOption=true;


    // Service Provider
    this.fieldVisiblity.campaignSeasonalVolume=false;
    this.fieldVisiblity.annualVolume=true;
    this.fieldVisiblity.hazardous=true;
    this.fieldVisiblity.numberOfRailCarsRequired=true;

    //Shipping->Orgin
    this.fieldVisiblity.shippingName=true;
    this.fieldVisiblity.orginStreetAddress=true;
    this.fieldVisiblity.orginCity=true;
    this.fieldVisiblity.orginState=true;
    this.fieldVisiblity.orginCountry=true;
    this.fieldVisiblity.orginZipCode=true;
    this.fieldVisiblity.orginAlternateServingRailRoad=true;
    this.fieldVisiblity.orginRailStation=true;
    this.fieldVisiblity.orginServingRailRoad=true;
    this.fieldVisiblity.orginSiteCompetitivenessId=true;

    //shipping-> destination
    this.fieldVisiblity.consigneeName=true;
    this.fieldVisiblity.destinationStreetAddress=true;

    this.fieldVisiblity.destinationCity=true;
    this.fieldVisiblity.destinationState=true;
    this.fieldVisiblity.destinationCountry=true;
    this.fieldVisiblity.destinationZipCode=true;
    this.fieldVisiblity.destinationServingRailRoad=true;
    this.fieldVisiblity.destinationRailStation=true;
    this.fieldVisiblity.destinationAlternateServingRailRoad=true;
    this.fieldVisiblity.destinationSiteCompetitivenessId=true;


    //Payer Options
    this.fieldVisiblity.payer=true;
    this.fieldVisiblity.routing= true;
    this.fieldVisiblity.tariffNumber= true;
    this.fieldVisiblity.freightBillAddress= true;
    //Additional Info
    this.fieldVisiblity.customerPreferedCarrier=true;
    this.fieldVisiblity.costVsService=true;
    this.fieldVisiblity.isCostVsServiceMandatory=true;
    this.fieldVisiblity.comments=true;

    this.fieldVisiblity.materialDoc=true;
    this.fieldVisiblity.materialLink=true;
    this.fieldVisiblity.sdsLink=true;
    this.fieldVisiblity.sdsAttachment=true;
    this.fieldVisiblity.additionalAttachment=true;
    this.fieldVisiblity.additionalAttachment2=true;
    
  }
  onAnnualVolumeChange(event: any){
    this.onCalculateNumberofCars( event.target.value,this.requestData.annualVolumeUnitId );

  }

  onAnnualVolumeUomChange(event:any){
    this.onCalculateNumberofCars( this.requestData.annualVolume,event.value );
  }

  onCalculateNumberofCars(annualVolume:number,annualVolumeUOM: number)
  {
    this. _requestModel.retrieveNumberOfCars(this.transportType,annualVolume,annualVolumeUOM).subscribe(
      { next:result=>{
      this.requestData.numberOfRailCarsRequired=result;
    }, error:err=>{
      this.requestData.numberOfRailCarsRequired=0;
    }
    })
  }
  override onArticleClear(event:any){
    this.resetArticleData();
  }
  // override onCountryCheck(event:any){

  // }
  onArticleComplete(event:any){
    super.retrieveProductDetails(event)
  }
  onArticleSelected(event:any){
    this.processArticleNumber(event);

  }

 
  retrieveHeaderValues() {
    this.title = "Rail Transportation";
    this.description = "A \"Rush Request\" defined as Procurement providing rates to BU after 2 business days otherwise Procurement will provide rates within 7 business days for standard requests.";
  }
  resetArticleData() {
    this.requestData.mfg = "";
    this.selectedMFGCode = "";
    this.selectedArticle = [];
    this.requestData.product = "";
    this.requestData.articleNumber = "";

  }
   GenerarateArticleNumberEvent() {
    this.selectedArticle.label = this.requestData.articleNumber;
    return {
      label: this.requestData.articleNumber,
      value:this.requestData.product
    };
  }
 
  handlehazardousChange(event:any)
    {
      console.log("");
    
    }
  

  processArticleNumber(event:any){
    this.spinners.show();
    this.requestMaster.mfgOpts=[];
    this.selectedMFGCode="";
    this.requestData.articleNumber = event.label;
    this.requestData.product=event.value;
    this.productModel.retrieveProductForArticle(event.label).subscribe({
      next:result => {
        this.mappedMfgArticleList=result;
        this.requestMaster.mfgOpts=this.mappedMfgArticleList.filter((am:any)=>am.isactive).map((mfg:any)=>{return {label:`${mfg.mfgCode},${mfg.mfg}`,value:mfg.mfgCode}});
        if(this.requestMaster.mfgOpts.length==1){
          this.selectedMFGCode=this.requestMaster.mfgOpts[0].value;
          this.onMFGChange({value:this.requestMaster.mfgOpts[0].value});
        }
        this.spinners.hide();
      },
      error:error => {
        this._toast.error(this._translate.instant(error),this._translate.instant("ERROR"))
        this.spinners.hide();
      }
      
    });
    
  }

  onMFGChange(event:any){
    let mfgSelected=this.mappedMfgArticleList.find(mfg=>mfg.mfgCode==event.value);
    this.requestData.mfg=mfgSelected?.mfg;    
  }

  public clearForm() {
    this.OnCancel();
    }
  override OnCancel() {
    this.clearData(4);
    this.requestData.articleNumber="";
    this.requestData.railCartTypeId=0;
    this.requestData.mfg="";
    this.requestData.payerOption="";

    //Service Provider
    this.requestData.numberOfRailCarsRequired=0;
    this.requestData.centerRear="";
    this.requestData.hazardous="";

    //Payer Options
    this.requestData.freightBillAddress="";
    this.requestData.payer="";
    this.requestData.routing="";
    this.requestData.tariffNumber="";

    //Shipping ->Orgin
    this.requestData.orginStreetAddress="";
    this.requestData.orginServingRailRoad="";
    this.requestData.orginAlternateServingRailRoad="";
    this.requestData.orginSiteCompetitivenessId =0;
    this.requestData.orginRailStation="";

    
    //Shipping->Destination
    this.requestData.destinationStreetAddress="";
    this.requestData.destinationServingRailRoad="";
    this.requestData.destinationAlternateServingRailRoad="";
    this.requestData.destinationSiteCompetitivenessId =0;
    this.requestData.destinationRailStation="";
    this.resetArticleData();
  }
  onSubmitRequest() {
    this.handlingDefaultDropdownValues();
    this.requestData.annualVolumeUnitId=this.requestData.annualVolumeUnitId??0;

    if(this.requestData.orginSiteCompetitivenessId!=0 
      && !this.requestMaster.siteCompetitiveness.find((s:any)=>s.value==this.requestData.orginSiteCompetitivenessId))
    {
      this.requestData.orginSiteCompetitivenessId=0;
    }
    if(this.requestData.destinationSiteCompetitivenessId!=0 
      && !this.requestMaster.siteCompetitiveness.find((s:any)=>s.value==this.requestData.destinationSiteCompetitivenessId))
    {
      this.requestData.destinationSiteCompetitivenessId=0;
    }
    if(this.requestData.railCartTypeId!=0
      && !this.requestMaster.railCarType.find((r:any)=>r.value==this.requestData.railCartTypeId))
    {
      this.requestData.railCartTypeId=0;
    }
    this.createRequest();
  }

  
  requestTypeChangeEvent(event:any){
    console.log('');
}
bindConditionalFields(){
  console.log("")

}
onSpecialRequirement(event:any){

}
onPlantSelectPopulate(event:any,index:number){
  console.log('')

}
}