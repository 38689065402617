import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { RequestDetailsComponent } from '../../request-details-form.component';
import { LoggedInUserInfoModel } from 'src/app/site-permissions/loggedin-user-access.model';
import { RequestFormModel } from 'src/app/requests/request-form.model';
import { LessThanTruckloadRequestFormModel } from 'src/app/requests/less-than-truckload-request-form.model';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppServiceService } from 'src/app/services/app-service.service';
import { Subscription } from 'rxjs';
import { LessThanTruckloadRequestDetails } from 'src/app/requests/data/formdata/less-than-truckload-request-details.data';

@Component({
  selector: 'app-less-than-truckload-request-detail-form-na',
  templateUrl: '..\\..\\..\\shared-templates\\request-details-form-template.html',
  styleUrls: ['..\\..\\..\\shared-templates\\request-details-form-template.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [LoggedInUserInfoModel,
    { provide: RequestFormModel, useClass: LessThanTruckloadRequestFormModel }
  ]
})
export class LessThanTruckloadRequestDetailFormNaComponent extends RequestDetailsComponent implements OnInit, OnDestroy {
 

  private subscriptionName: Subscription; //important to create a subscription
  messageReceived: any;
  pageLanguage: string = "";
  override requestData: any;

  constructor(userAccessModel: LoggedInUserInfoModel, requestModel: RequestFormModel,
    toast: ToastrService, router: Router, spinner: NgxSpinnerService, route: ActivatedRoute, private _appServ: AppServiceService) {
    super(userAccessModel, requestModel, toast, router, spinner, route);
    this.subscriptionName = this._appServ.getUpdate().subscribe(message => {
      this.messageReceived = message;
      let newLang = sessionStorage.getItem('selectedLanguage');
      if (this.pageLanguage != newLang && this.pageLanguage != "") {
        this.ngOnInit();
      }
    });
  }
  ngOnInit(): void {
    this.pageLanguage = sessionStorage['selectedLanguage'];
    this.requestData = new LessThanTruckloadRequestDetails();
    this.transportType = 7;
    this.validateloggedInUser();
    this.readQueryString();
    this.retrieveHeaderValues();
    this.bindfiledVisiblity();

  }
  ngOnDestroy() { 
    this.subscriptionName.unsubscribe();
  }
  retrieveHeaderValues() {
    this.title = "Less-Than-Truckload";
  }
  bindfiledVisiblity()
  {
    this.fieldVisiblity.requestType=true;
    this.fieldVisiblity.startDate=true;
    this.fieldVisiblity.requestor=true;
    this.fieldVisiblity.bussinessunit=true;
    this.fieldVisiblity.product=true;
    this.fieldVisiblity.naCountry=true;
    this.fieldVisiblity.articleNumber=true;
    this.fieldVisiblity.equipmentType=true;
    // Service Provider
    this.fieldVisiblity.volumeType=true;
    this.fieldVisiblity.tempControl=true;
    this.fieldVisiblity.dropTrail=true;
    this.fieldVisiblity.days=true;
    this.fieldVisiblity.hazardous=true;
    this.fieldVisiblity.unNumber=true;
    this.fieldVisiblity.tankerEndorsement=true;

    //Shipping->Orgin
    this.fieldVisiblity.originPlant=true;
    this.fieldVisiblity.shippingName=true;
    this.fieldVisiblity.orginCity=true;
    this.fieldVisiblity.orginState=true;
    this.fieldVisiblity.orginCountry=true;
    this.fieldVisiblity.orginZipCode=true;
    //shipping-> destination
    this.fieldVisiblity.destinationPlant=true;
    this.fieldVisiblity.consigneeName=true;
    this.fieldVisiblity.destinationCity=true;
    this.fieldVisiblity.destinationState=true;
    this.fieldVisiblity.destinationCountry=true;
    this.fieldVisiblity.destinationZipCode=true;

    //Additional Info
    this.fieldVisiblity.comments=true;

    this.fieldVisiblity.materialDoc=true;
    this.fieldVisiblity.materialLink=true;
    this.fieldVisiblity.sdsLink=true;
    this.fieldVisiblity.sdsAttachment=true;
    this.fieldVisiblity.additionalAttachment=true;
    this.fieldVisiblity.additionalAttachment2=true;

    if(this.isVendorUser){
      this.fieldVisiblity.requestor=false;
      this.fieldVisiblity.customerPreferedCarrier=false;
      this.fieldVisiblity.costVsService=false;
    }
    
  }
  override downLoadFISPQFile() {
    console.log("");
  }
  override bindConditionalFields() {
    let LTTReqData=this.requestData as LessThanTruckloadRequestDetails;
    if(LTTReqData.naCountry==="Canada"){
      this.fieldVisiblity.interModel=true;
      this.fieldVisiblity.seaFreightPreLeg=true;
      this.fieldVisiblity.seaFreightOnCarriage=true;
      this.fieldVisiblity.averageWeightOfShipment=true;
      this.fieldVisiblity.averageNumberOfPalletsOrTotesPerShipment=true;
      this.fieldVisiblity.palletDimensions=true;
      this.fieldVisiblity.equipmentTypeMultiSelect=false;
      this.fieldVisiblity.tankerEndorsement=false;
    }
    else if(LTTReqData.naCountry==="Mexico"){

      this.fieldVisiblity.equipmentTypeMultiSelect=true;
      this.fieldVisiblity.tempControlGeneralHazmat=true;
      this.fieldVisiblity.generalHazmatLoad=true;
      this.fieldVisiblity.tankerEndorsement=true;
    }
          

  }
}
