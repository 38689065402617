import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RequestFormModel } from 'src/app/requests/request-form.model';
import { TruckLoadRequestFormModel } from 'src/app/requests/truck-load-request-form.model';
import { LoggedInUserInfoModel } from 'src/app/site-permissions/loggedin-user-access.model';
import { RequestDetailsComponent } from '../../request-details-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppServiceService } from 'src/app/services/app-service.service';
import { TruckLoadRequestDetails } from 'src/app/requests/data/formdata/truck-load-request-details.data';

@Component({
  selector: 'app-truck-load-request-details-form-ap',
  templateUrl: '..\\..\\..\\shared-templates\\request-details-form-template.html',
  styleUrls: ['..\\..\\..\\shared-templates\\request-details-form-template.css'],

  providers: [LoggedInUserInfoModel,
    { provide: RequestFormModel, useClass: TruckLoadRequestFormModel }
  ]
})
export class TruckLoadRequestDetailsFormApComponent extends RequestDetailsComponent implements OnInit {

  private subscriptionName: Subscription; //important to create a subscription
  messageReceived: any;
  pageLanguage: string = "";
  override requestData: any;

  constructor(userAccessModel: LoggedInUserInfoModel, requestModel: RequestFormModel,
    toast: ToastrService, router: Router, spinner: NgxSpinnerService, route: ActivatedRoute, private _appServ: AppServiceService) {
    super(userAccessModel, requestModel, toast, router, spinner, route);
    this.subscriptionName = this._appServ.getUpdate().subscribe(message => { //message contains the data sent from service
      this.messageReceived = message;
      let newLang = sessionStorage.getItem('selectedLanguage');
      if (this.pageLanguage != newLang && this.pageLanguage != "") {
        this.ngOnInit();
      }
    });

  }
  ngOnInit(): void {
    this.pageLanguage = sessionStorage['selectedLanguage'];
    this.requestData = new TruckLoadRequestDetails();
    this.transportType = 3;
    this.validateloggedInUser();
    this.readQueryString();
    this.retrieveHeaderValues();
    this.bindfieldVisiblity()
  }
  ngOnDestroy() {
    this.subscriptionName.unsubscribe();
  }
  retrieveHeaderValues() {
    this.title = "PACKED_TRANSAPORTATION";
  }
  bindfieldVisiblity() {
    //General
    this.fieldVisiblity.requestType = true;
    this.fieldVisiblity.startDate = true;
    this.fieldVisiblity.requestor = true;
    this.fieldVisiblity.bussinessunit = true;
    this.fieldVisiblity.product = true;
    this.fieldVisiblity.equipmentType = true;
    this.fieldVisiblity.equipmentTypeMultiSelect = true;
    this.fieldVisiblity.naCountry = true;
    this.fieldVisiblity.articleNumber = true;

    this.fieldVisiblity.packageType=true;
    this.fieldVisiblity.remarkPackageType=true;
    this.fieldVisiblity.shippingWithPallet=true;
    this.fieldVisiblity.palletSize=true;
    this.fieldVisiblity.ftlOrLtl=true;
    this.fieldVisiblity.ftlTruckType=true;
    this.fieldVisiblity.ltlRange=true;
    this.fieldVisiblity.allowTwoLayerStack=true;
    this.fieldVisiblity.interModel=true;

    // Service Provider
    this.fieldVisiblity.volumeType = true;
    this.fieldVisiblity.annualVolume = true;
    this.fieldVisiblity.tempControl = true;
    this.fieldVisiblity.dropTrail = true;
    this.fieldVisiblity.hazardous = true;
    this.fieldVisiblity.unNumber = true;

    this.fieldVisiblity.shipmentFrequency=true;
    this.fieldVisiblity.dangerousGoodsClass=true;
    this.fieldVisiblity.tariffZone=true;
    this.fieldVisiblity.driverSupportRequired=true;

    //Shipping->Orgin
    this.fieldVisiblity.originPlant = true;
    this.fieldVisiblity.shippingName = true;
    this.fieldVisiblity.orginCity = true;
    this.fieldVisiblity.orginState = true;
    this.fieldVisiblity.orginCountry = true;
    this.fieldVisiblity.orginZipCode = true;
    //shipping-> destination
    this.fieldVisiblity.destinationPlant = true;
    this.fieldVisiblity.consigneeName = true;
    this.fieldVisiblity.destinationCity = true;
    this.fieldVisiblity.destinationState = true;
    this.fieldVisiblity.destinationCountry = true;
    this.fieldVisiblity.destinationZipCode = true;

    //Additional Info
    this.fieldVisiblity.customerPreferedCarrier = true;
    this.fieldVisiblity.costVsService = true;
    this.fieldVisiblity.comments = true;

    this.fieldVisiblity.materialDoc = true;
    this.fieldVisiblity.materialLink = true;
    this.fieldVisiblity.sdsLink = true;
    this.fieldVisiblity.sdsAttachment = true;
    this.fieldVisiblity.additionalAttachment = true;
    this.fieldVisiblity.additionalAttachment2 = true;

    if (this.isVendorUser) {
      this.fieldVisiblity.requestor = false;
      this.fieldVisiblity.customerPreferedCarrier = false;
      this.fieldVisiblity.costVsService = false;
    }

  }
  downLoadFISPQFile() {
    console.log("");
  }
  bindConditionalFields() {
    console.log(this.requestData)
    if(this.requestData.internModel=="Yes"){
      this.fieldVisiblity.intermodalType=true;
      this.fieldVisiblity.containerType=true;
    }
    else{
      this.fieldVisiblity.intermodalType=false;
      this.fieldVisiblity.containerType=false;
    }
    console.log(this.requestData.tempControl,"temp control")
    this.fieldVisiblity.tempRange=this.requestData.tempControl=="Yes";
  }




}
