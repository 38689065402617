import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppServiceService } from 'src/app/services/app-service.service';
import { TruckLoadRequestFormData } from '../../data/formdata/truck-load-request-form.data';

@Component({
  selector: 'app-truck-load-request-form-common',
  templateUrl: './truck-load-request-form-common.component.html',
  styleUrls: ['./truck-load-request-form-common.component.css'],
  providers:[AppServiceService]
})
export class TruckLoadRequestFormCommonComponent implements OnInit {

  //private subscription:Subscription;
  private subscriptionName: Subscription; //important to create a subscription
  messageReceived: any;
  region !: string;
  
  constructor(private _appServ:AppServiceService){
    this.subscriptionName= this._appServ.getUpdate().subscribe(message => { //message contains the data sent from service
      this.messageReceived = message;
      this.region=sessionStorage['selectedRegion'];
      
  
      });
  }


  ngOnInit():void{
console.log(this.region);
  }

}
